@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html{
  font-size: 14px !important;
}

body {
  margin: 0;
  font-family:
          'Poppins',
          -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary-color : #0B1058;
  --nav-color : rgba(0, 0, 0, 0.6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

/*.offset:before {*/
/*  display: block;*/
/*  content: "";*/
/*  height: 4rem;*/
/*  margin-top: -4rem;*/
/*}*/

.heading-hat {
  width: 3.5rem;
  height: .4rem;
  background-color: var(--primary-color);
  margin-bottom: .3rem;
}

/*---- Navigation -----*/
.navbar {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .1rem;
  background: var(--nav-color) !important;
  transition: background .2s ease-in-out;
}

.navbar-brand img {
  height: 3rem;
}

.navbar-nav li {
  padding-right: 0 !important;
  margin-right: 2.5rem;
  padding-top: .8rem;
}

.navbar-nav .nav-link{
  padding-left: 0 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  text-decoration: none;
  text-shadow: 0 0 2px #332E30;
  font-size: 0.85rem;
  /*padding-top: .8rem;*/
}

/*.navbar-dark .navbar-nav .nav-link.active,*/
/*.navbar-dark .navbar-nav .nav-link:hover {*/
/*  color: #1ebba3;*/
/*}*/

.navbar-selector {
  width: 70%;
  height: .15rem;
  background-color: transparent;
  transition: .3s;
  transition-delay: .15s;
  position: relative;
  left: 0;
}

.navbar-dark .navbar-nav .nav-item:hover .navbar-selector {
  background-color: #FFFFFF;
  box-shadow: 0 0 3px grey;
}

a, a:hover{
  text-decoration: none;
  cursor: pointer;
}

.caption h1 {
  font-size: 3.8rem;
  font-weight: 700;
  letter-spacing: .3rem;
  text-shadow: .1rem .1rem .8rem black;
  padding-bottom: 1rem;
}

.caption h3 {
  font-size: 2rem;
  text-shadow: .1rem .1rem .5rem black;
}

.caption p {
  text-shadow: .1rem .1rem .5rem black;
}

.btn-lg {
  border-width: medium;
  border-radius: 0;
  padding: .6rem 1.3rem;
  font-size: 1.1rem;
}

/*----- landing page -----*/
.home-inner {
  /*background-image: url(./img/computers.png);*/
  position: relative;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-size: cover;*/
  /*background-position: center center;*/
}

/*.caption {*/
/*  width: 80%;*/
/*  max-width: 80%;*/
/*  position: absolute;*/
/*  top: 38%;*/
/*  z-index: 1;*/
/*  color: white;*/
/*  text-transform: uppercase;*/
/*}*/

/*----- Section A ------*/


.section-a {
  width: 100%;
  height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
}

.a-content{
  padding: 4rem 0 !important;
}

.a-photo {
  background-image: url(/static/media/frontis2.84db0f85.JPG);
  background-position: center;
  background-size: cover;
  filter: brightness(85%) sepia(10%);
  -webkit-filter: brightness(85%) sepia(10%);
  height: 100%;
  width: auto;
}

.col-photo {
  padding: 0 !important;
}

.section-heading {
  text-transform: uppercase;
  font-weight: bold;
}

.a-row p {
  width: 50%;
  padding: 0 !important;
  font-size: 1.2rem;
}

.a-row h4 {
  font-weight: bold;
  text-align: center;
}

.a-row {
  height: 25%;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 3rem;
  align-items: center;
}

.a-icon {
  width: 6rem;
  height: 6rem;
  display: inline-block;
}

.certificate-icon {
  -webkit-mask: url(/static/media/certificate.2ab49f98.svg);
          mask: url(/static/media/certificate.2ab49f98.svg);
  -webkit-mask-size: cover;
          mask-size: cover;
  background: var(--primary-color);
}

.printer-icon {
  -webkit-mask: url(/static/media/printer.7bc8b159.svg);
          mask: url(/static/media/printer.7bc8b159.svg);
  -webkit-mask-size: cover;
          mask-size: cover;
  background: var(--primary-color);
}

.client-icon {
  -webkit-mask: url(/static/media/client.d4ba11a7.svg);
          mask: url(/static/media/client.d4ba11a7.svg);
  -webkit-mask-size: cover;
          mask-size: cover;
  width: 8rem !important;
  height: 7rem !important;
  background: var(--primary-color);
}

.icon-container {
  width: 10rem;
  text-align: center;
  /*border: 1px solid blue;*/
}

.btn-secondary {
  border-width: medium;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: .6rem 1.2rem;
  color: white;
  background-color: #1ebba3;
  border-color: #1ebba3;
}

.btn-secondary:hover {
  background-color: #189582;
  border-color: #189582;
}

/*----- SectionB -----*/

.zero-padding {
  padding: 0 !important;
}

.section-b {
  width: 100%;
}

.b-row {
  height: 100vh !important;
  overflow: hidden !important;
}

.b-col {
  padding: 0 !important;
  overflow: hidden !important;
  position: relative;
}

.b-container {
  background-position: center;
  transition: transform .3s ease;
  position: absolute;
  height: 100%;
  width: 100%;
}

.b-text {
  padding: 5rem 3% !important;
  /*font-weight: bold;*/
  font-size: 1.2rem;
  text-align: left;
}

.b-text h2 {
  margin-bottom: 2rem;
}

.b-container:hover .b-photo-rent, .b-container:hover .b-photo-print, .b-container:hover .b-photo-tec {
  transform: scale(1.05);
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.b-col:hover p {
  transform: translate(0, 0);
  color: white;
}

.b-photo-rent {
  height: 100%;
  background-image: url(/static/media/bs1.f6755b34.png);
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  transition: transform .3s ease, -webkit-filter .3s ease;
  transition: transform .3s ease, filter .3s ease;
  transition: transform .3s ease, filter .3s ease, -webkit-filter .3s ease;
}


.b-photo-print {
  height: 100%;
  background-image: url(/static/media/bs2.0f7c0a0b.png);
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  transition: transform .3s ease, -webkit-filter .3s ease;
  transition: transform .3s ease, filter .3s ease;
  transition: transform .3s ease, filter .3s ease, -webkit-filter .3s ease;
}

.b-photo-tec {
  height: 100%;
  background-image: url(/static/media/bs3.7ec91247.png);
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  transition: transform .3s ease, -webkit-filter .3s ease;
  transition: transform .3s ease, filter .3s ease;
  transition: transform .3s ease, filter .3s ease, -webkit-filter .3s ease;
}

.text-box {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999 !important;
  transform: translate(-50%, -50%);
}

.text-box h4 {
  padding: 0.5rem 2rem;
  text-align: center;
  font-weight: lighter;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
}

.text-box p {
  text-align: center;
  line-height: 2rem;
  padding-top: 1rem;
  color: transparent;
  transform: translate(0, -25%);
  transition: transform .3s ease, color .3s ease;
}


.b-photo-rent:hover  .text-box {
  transition: none !important;
}


h3.heading {
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.9rem;
}

.feature h3{
  font-size: 1.3rem;
  text-transform: uppercase;
  padding-bottom: .4rem;
}

.feature p {
  font-size: 1.1rem;
}

/*----- Section C -----*/
.c-section {
  width: 100%;
}

.c-col {
  height: 100vh !important;
}

.c-title{
  padding: 3rem 0 !important;
}

.c-title h2{
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
}

.product-card {
  padding: 10% 10%;
  height: 40vh;
  width: 100% !important;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-filter: drop-shadow(0 0 5px #A8A8A8);
          filter: drop-shadow(0 0 5px #A8A8A8);
  transition: -webkit-filter .3s ease;
  transition: filter .3s ease;
  transition: filter .3s ease, -webkit-filter .3s ease;
}

.product-card img {
  width: 90%;
  height: 90%;
  object-fit: contain !important;
}

.c-logo {
  padding: 10%;
  height: 100%;
}

.c-logo img {
  width: 90%;
  height: 90%;
  object-fit: contain !important;
}

.product-card p {
  font-weight: 300;
}

.product-card:hover {
  -webkit-filter: drop-shadow(0 0 6px #696969);
          filter: drop-shadow(0 0 6px #696969);
}

/*.dark {*/
/*  background-color: rgba(0, 0, 0, .75);*/
/*  color: white;*/
/*  padding: 7rem 2rem;*/
/*}*/
/*.fixed-background h3{*/
/*  margin-bottom: 2rem;*/
/*}*/
/*.fixed-background p {*/
/*  margin-top: 1.5rem;*/
/*}*/

/*---- Section D -----*/

.d-container {
  height: 100vh;
  padding: 0 !important;
}

.footer-container {

}

.d-col {
  height: 50% !important;
  padding: 0 !important;
}


.map-section {
  height: 100vh;
  padding: 0 !important;
}

.icons-row {
  display: flex;
  justify-content: center;
  align-items: center;
}


/*.sectiond img {*/
/*  width: 100%;*/
/*  border-radius: 50%;*/
/*}*/

/*blockquote .fas {*/
/*  color: #1ebba3;*/
/*  margin: -0.1rem 1rem;*/
/*  float: left;*/
/*}*/
/*.sectiond-hr {*/
/*  border-top: .05rem solid #1ebba3;*/
/*}*/

footer {
  height: 100%;
  width: 100%;
  background-color: #000000;
  color: white;
  padding: 2rem 0 2rem;
  display: flex;
  align-items: center;
}

footer img {
  height: 3rem;
  margin: 1.5rem 0;
}

hr.socket {
  width: 100%;
  border-top: .2rem solid #666b71;
  margin-top: 3rem;
}

.footer-icon {
  font-size: 1.6rem;
  margin: .5rem 1rem;
  color: white;
}

.divider{
  height: 1px;
  width: 100%;
  background-color: #FFFFFF;
  margin: 1rem 0;
}

/*----- Media Queries -----*/

@media(max-width: 991px) {
  .caption h1 {
    font-size: 2.3rem;
    letter-spacing: .15rem;
    padding-bottom: .5rem;
  }
  .caption h3 {
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
  }
  .btn-lg {
    padding: .5rem 1rem;
    font-size: 1rem;
  }
  .narrow h1 {
    font-size: 1.5rem;
  }
  .sectiond img {
    max-width: 50%;
    display: block;
    margin: 0 auto;
  }

  .navbar {
    background: rgba(0,0,0, .6) !important;
  }

  .navbar-dark .navbar-nav .nav-item:hover .navbar-selector {
    background-color: transparent;
    box-shadow: none;
  }

  .carousel-caption {
    text-align: center !important;
  }

  .a-photo {
    display: none;
  }

  .section-a {
    height: auto;
  }

  .section-a .a-title {
    height: 120px;
  }

  .section-a .a-row {
    height: auto;
    padding: 1px 0;
    margin-top: 10px;
  }

  .b-row {
    height: auto !important;
  }

  .b-text{
    height: auto !important;
  }

  .b-col {
    height: 500px !important;
  }

  .b-col p {
    transform: translate(0, 0);
    color: white;
  }

  .b-container .b-photo-rent, .b-container .b-photo-print, .b-container .b-photo-tec {
    transform: scale(1.05);
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }

  .c-col {
    height: auto !important;
  }

  .c-title {
    height: auto !important;
  }

  .product-card {
    height: 300px !important;
  }

  .brands-carousel {
    height: auto;
    padding: 0 !important;
    margin: 0 !important;
  }

  .sm-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .d-container {
    height: 200vh;
  }
}


/*============ BOOTSTRAP BREAK POINTS:

Extra small (xs) devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap

Small (sm) devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

Medium (md) devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

Large (lg) devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

Extra (xl) large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

=============*/



/*--- Extra Bootstrap Column Padding --*/
[class*="col-"] {
  padding: 1rem;
}

/*--- Bootstrap Mobile Gutter Fix --*/
.row, .container-fluid {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/*--- Fix for Fixed Navbar jumping on scroll --*/
.fixed-top  {
  -webkit-backface-visibility: hidden;
}

/*--- Fixed Landing Page Section --*/
.landing {
  position: relative;
  width: 100%;
  height: 100vh;
  /*z-index: -1;*/
}

.home-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.react-multi-carousel-dot button{
  background-color: transparent;
  /*font-size: large;*/
  height: 20px !important;
  width: 20px !important;
  margin: 0 1rem !important;
  margin-bottom: 100% !important;
  border-color: white !important;
}

.react-multi-carousel-dot--active button{
  background-color: white !important;
}

.button-landing {
  width: 270px;
  height: 70px;
  font-size: 1.3rem;
  font-weight: 200;
  border-radius: 10px;
  border: 1px solid white;
  color: white;
}

.button-landing:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.button-landing:focus {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.button-landing:active {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.carousel-caption {
  position: absolute;
  z-index: 999 !important;
  top: 40%;
  text-align: left;
  text-transform: uppercase;
}

.carousel-img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}

/*--- iOS Fixed Background Image --*/
.fixed-background {
  /*position: relative;*/
  width: 100%;
}

.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*z-index: -999!important;*/
}

.fixed {
  /*position: fixed;*/
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  /*-webkit-transform: translateZ(0);*/
  /*transform: translateZ(0);*/
  /*will-change: transform;*/
}
